console.log("[Marketing] - Vite ⚡️ Rails");

const mobileMenu = document.getElementById("mobile-nav-menu");
const mobileButtonToggle = document.getElementById("mobile-nav-toggle");

mobileButtonToggle.onclick = function (e) {
  mobileMenu.classList.toggle("hidden");
  e.currentTarget.ariaExpanded = !(e.currentTarget.ariaExpanded === "true");

  Array.from(e.currentTarget.children).forEach((child, index) => {
    if (index === 0) {
      child.classList.toggle("rotate-45");
      child.classList.toggle("translate-y-[10px]");
    } else if (index === 1) {
      child.classList.toggle("-rotate-45");
    } else {
      child.classList.toggle("-rotate-45");
      child.classList.toggle("translate-y-[-10px]");
    }
  });
};
